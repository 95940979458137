@import 'vendor/styles/theme-teldat.scss';

/////////// BUTONS //////////////

.element {
    font-size: 1.2rem;
    cursor: pointer;
}

.no-hover-element {
    font-size: 1.2rem;
}

.disabled-element {
    font-size: 1.2rem;
}

.element:hover {
    background-color: rgba(24, 28, 33, 0.035);
    .navigate-arrow {
        left: 30px;
        visibility: visible;
    }
}

.element-active {
    background-color: rgba(53, 221, 236, 0.5) !important;
}

.text-logo {
    margin-top: 0.5rem;
}

.element {
    .navigate-arrow {
        position: absolute;
        left: 0px;
        top: 35%;
        transition: left 0.2s;
        visibility: hidden;
        color: $primary-color;
    }
    .arrow-container {
        width: 100px;
        position: relative;
    }
}

////////// BUTTONS CHECK TRIANGLE ////////////

.inner-triangle{
    border-left: 30px solid transparent;
    border-right: 40px solid $primary-color !important;
    border-bottom: 40px solid transparent;
    height: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    font-size: 0.75rem;
    border-top-right-radius: 5px;
}

.inner-grey-triangle{
    border-left: 30px solid transparent;
    border-right: 40px solid grey !important;
    border-bottom: 40px solid transparent;
    height: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    font-size: 0.75rem;
    border-top-right-radius: 5px;
}

.fa-lock{
    margin-right: -77px;
    margin-top: 5px;
    font-size: large;
    font-weight: 100px;
    font-style: bold;
}

.fa-check{
    margin-right: -45px;
    font-size: medium;
    font-weight: 100px;
    font-style: bold;
}

.hide-element {
    display: none;
}

///////// SETTINGS CARD ////////

.setting-card{
    padding-top: 0 !important;
    margin-top: 1.5rem !important;
}

//////// CONFIG CHECKBOX ////////
.switch-container {
    width: toEm(55, 13);
}
.switch {
    display: inline-block;
    position: relative;
    width: toEm(45, 13);
    height: toEm(20, 13);
    border-radius: toEm(20, 13);
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
    margin-left: toEm(10, 13);
    margin-top: 0.5rem;
}
.switch::before {
    content: '';
    position: absolute;
    top: toEm(1, 13);
    left: toEm(2, 13);
    width: toEm(18, 13);
    height: toEm(18, 13);
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: #72da67;
}
input:checked + .switch::before {
    left: toEm(26, 13);
    background: #fff;
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}

/////////// BUTTONS //////////@at-root

.select-profile{
      margin-left: 10rem;
}