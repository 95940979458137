.content-layout {
  padding-left: 76px;
  height: 100vh;
  transition: all 0.3s ease-in-out;

  .sidebar-expanded & {
    padding-left: 296px;
  }
}

.content-layout-body {
  padding: 16px 36px 50px 36px;
  height: calc(100vh - 74px);
  overflow: auto;
}

.menu-navbar {
  position: fixed;
  z-index: 1030;
}

// NAVBAR START
.color-navbar {
  background-color: #eaf1f0;
}

.title-navbar {
  color: $primary-color;
  font-size: 1.6rem;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 700;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
}

// NAVBAR END

// SIDEBAR START
.title-sidebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  font-size: 1.15rem;
  line-height: 1.5rem;
  margin-top: 8px;
}

.nav-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_list {
  margin-top: 20px;
}

.l-navbar-main {
  width: 76px;
  position: unset;
  top: 0;
  height: 100vh;
  background-color: $primary-color;
  padding: 0.5rem 1px 0 0;
  transition: 0.5s;
  z-index: 2;
  box-shadow: inset -1px 0 0 0 #4d679a;
  position: relative;
}

.l-navbar-secondary {
  width: 230px;
  height: 100vh;
  background-color: $primary-color;
  padding: 1.25rem 0.25rem 0 1.25rem;
  transition: all 0.3s ease-in-out;
  transform: translateX(-154px);
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;

  .sidebar-expanded & {
    transform: translateX(76px);
  }
}

.btn-primary.btn-expand {
  padding: 0;
  background: $primary-color;
  border-radius: 100%;
  border: 2px solid #5470a8;
  position: absolute;
  right: -13px;
  top: 23px;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  svg {
    transition: all 0.3s ease-in-out;
  }
}

.btn-primary.btn-expand {
  .sidebar-expanded & {
    transform: translateX(228px);

    svg {
      transform: scale(-1, 1);
    }
  }
}

.l-nav-content {
  height: 94vh;
  padding-right: 0.5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s ease-in-out;
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
    background-color: #4d679a;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }

  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5470a8;
  }

  &::-webkit-scrollbar-thumb:active {
    background: #5470a8;
  }
}

.nav_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 66px;
  font-size: 9.5px;
  padding: 0.5rem 0;
  margin: 0 auto 0.4rem;
  border-radius: 0;
  position: relative;
  color: white;
  transition: 0.3s;

  &:hover,
  &.is-active {
    background: #5470a8;
  }
}

.btn.nav_link {
  &:hover,
  &.is-active {
    background: #5470a8;
  }

  &:hover {
    filter: none;
  }
}

.nav_icon,
.nav_msg {
  color: white;
}

.nav_msg {
  font-size: 0.6rem;
  margin-top: 0.2rem;
}

.logo-sidebar {
  text-align: center;
  margin-top: -7px;
  margin-bottom: 13px;
}

.nav_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0 0 0.5rem;
}

.nav_menu {
  display: block;
  padding: 0.3rem 0;

  .nav_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav_name {
    font-size: 1.1rem;
    color: white;
  }

  .icon {
    color: white;
  }

  .switch {
    font-size: 0.7em;
  }

  & > .nav_options {
    display: none;
  }

  .nav_menu {
    margin-left: 1rem;

    .nav_name {
      font-size: 1rem;
    }
  }

  &.is-disabled {
    opacity: 0.6;
  }

  &.expanded {
    & > .nav_options {
      display: block;
    }

    & > .nav_title {
      .btn-primary .icon {
        transform: rotate(180deg);
      }
    }
  }
}

.nav_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  color: white;
  transition: all 0.3s ease-in-out;

  &:hover,
  &.is-active {
    color: white;
    background: #5470a8;
  }
}

// SIDEBAR END

.mh-50 {
  max-height: 500px !important;
}

.maxh-300 {
  max-height: 300px;
}

.minh-300 {
  min-height: 300px;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.obj-cus-h-35 {
  height: 35rem !important;
}

.vw-90 {
  max-width: 90vw !important;
}

.vw-60 {
  max-width: 60vw !important;
}

.minw-150 {
  min-width: 150px;
}

.minw-300 {
  min-width: 300px;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-13 {
  width: 13% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.pr-50 {
  padding-right: 50px;
}

.w-0 {
  width: 0;
}

.h-0 {
  height: 0;
}

.card-body {
  padding: 7px 1.3rem 0px 1.3rem;
}

.content-small {
  padding: 2px 15px 0px 15px;
}

.center-div {
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.center-span {
  display: table;
  margin: 0 auto;
  margin-bottom: -10px;
}

.ui-w-60 {
  width: 60px !important;
  height: auto;
}

.ui-bg-cover {
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}

.ui-bg-overlay-container {
  position: relative;

  > * {
    position: relative;
  }

  .ui-bg-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }
}

.z-index-1 {
  z-index: 1;
}
