// Button groups
//

@if not $material-style {
  :not(.btn-group):not(.input-group-prepend):not(.input-group-append)>.btn-group>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .btn-group>.btn-group:first-child>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-prepend>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-append>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-prepend>.btn-group:first-child>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child,
  .input-group-append>.btn-group:first-child>.btn:not(.btn-default):not([class*="btn-outline-"]):first-child {
    @include ltr-style {
      border-left-color: transparent;
    }
    @include rtl-style {
      border-right-color: transparent;
    }
  }

  :not(.btn-group):not(.input-group-prepend):not(.input-group-append)>.btn-group>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type,
  .btn-group>.btn-group:last-child>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type,
  .input-group-append>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type,
  .input-group-prepend>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type,
  .input-group-append>.btn-group:last-child>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type,
  .input-group-prepend>.btn-group:last-child>.btn:not(.btn-default):not([class*="btn-outline-"]):last-of-type {
    @include ltr-style {
      border-right-color: transparent;
    }
    @include rtl-style {
      border-left-color: transparent;
    }
  }
}

// *******************************************************************************
// * Sizing

.btn-group-xs > .btn {
  @extend .btn-xs;
}

.btn-group-xl > .btn {
  @extend .btn-xl;
}

// *******************************************************************************
// * Split button

.dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.input-group-lg .btn + .dropdown-toggle-split,
.btn-xl + .dropdown-toggle-split,
.btn-group-xl > .btn + .dropdown-toggle-split {
  padding-right: .7em;
  padding-left: .7em;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.input-group-sm .btn + .dropdown-toggle-split {
  padding-right: .6em;
  padding-left: .6em;
}

.btn-xs + .dropdown-toggle-split,
.btn-group-xs > .btn + .dropdown-toggle-split {
  padding-right: .5em;
  padding-left: .5em;
}

// *******************************************************************************
// * RTL

@include rtl-only {
  .btn-group .btn[class] {
    @include border-radius($border-radius);
  }

  .btn-group .btn-xs[class],
  .btn-group-xs .btn[class] {
    @include border-radius($border-radius-xs);
  }

  .btn-group .btn-sm[class],
  .btn-group-sm .btn[class] {
    @include border-radius($border-radius-sm);
  }

  .btn-group .btn-lg[class],
  .btn-group-lg .btn[class] {
    @include border-radius($border-radius-lg);
  }

  .btn-group .btn-xl[class],
  .btn-group-xl .btn[class] {
    @include border-radius($border-radius-xl);
  }

  .btn-group {
    > .btn:not(:first-child),
    > .btn-group:not(:first-child) {
      margin-left: 0;
      margin-right: -$btn-border-width;
    }

    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      @include border-left-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-right-radius(0);
    }
  }

  .btn-group-vertical {
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
      @include border-bottom-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
      @include border-top-radius(0);
    }
  }
}
