@import './utils';
@import './_appwork/include';
@import './_theme/common';

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

$primary-color: #435986;
$body-bg: #e7efef;
$maroon: #722849;
$sidebar-bg: #6a798c;
$white1: #e0e8e9;
$pure-white: #fff;
$light-gray: #dedddd;

body {
  background: $body-bg;
  font-family: "Open Sans","Helvetica Neue",Helvetica,sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.bg-body {
  background: $body-bg !important;
}

@include appwork-common-theme($primary-color);

// Navbar

@include appwork-navbar-variant('.bg-navbar-theme', $pure-white, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 1px 0 $border-color;
  .navbar-brand {
    margin-right: 0;
    span{
      padding-left: 0.5em;
    }
  }
}
.navbar-title-icon{
  margin-left: 5px;
}

// Sidenav

@include appwork-sidenav-variant('.bg-sidenav-theme', $sidebar-bg, $color: $white1);

.bg-sidenav-theme .sidenav-item{
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "sans-serif";
  font-weight: 700;
  letter-spacing: 0.08rem;
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 0em;
  .sidenav-link {
    padding: 1.5em 0.5em 1.5em 1.5em;
  }
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: $white1;
  color: $primary-color;
  border-right: 4px solid $maroon;
}


// Footer

@include appwork-footer-variant('.bg-footer-theme', $pure-white, $color: $text-muted, $active-color: $body-color);


// In multi-level modals, for lower models to look behind backdrop
.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}
.modal-backdrop.fade {
  opacity: 0;
}

.thick-border-bottom {
  border-bottom-width: 2px;
}